.react-icon {
    display: inline-block;
    margin-right: 4px;
    font-size: 1em;
    height: 1em;
    width: auto;
}

@media (min-width: 1400px) {
    .city-picker {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
    }
}


/* Mobile */

@media (min-width: 320px) and (max-width: 480px) {
    .city-picker {
        position: fixed;
        transform: none;
        left: auto;
        padding-right: 15px;
    }
    .mapboxgl-ctrl-bottom-right {
        padding-bottom: 60px;
    }
}